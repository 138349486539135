import React from "react";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";
import t from "../locale";

const Career = ({ pageContext }) => {
	const { title, content, language, careerTranslations, acf } = pageContext;

	return (
		<Layout
			seoTitle={title}
			lang={language}
			careerTranslations={careerTranslations}
			translationEN=""
			translationDE=""
		>
			<SmallBanner
				title={title}
				aditionalBreadcrump={t("Rekrutacja", language)}
				aditionalBreadcrumpLink={t("/rekrutacja/", language)}
			/>

			<section className="recruitment-single">
				<div className="container">
					<div className="row">
						<div className="col-lg-4">
							<div className="recruitment-single--info">
								<h4>{title}</h4>
								{acf.contactInfo ? (
									<div
										dangerouslySetInnerHTML={{
											__html: acf.contactInfo,
										}}
									/>
								) : (
									<p>
										{t(
											"Osoby zainteresowane pracą prosimy o wysłanie CV na adres",
											language
										)}
										:{" "}
										<a href="mailto:rekrutacja@palettenwerk.pl">
											rekrutacja@palettenwerk.pl
										</a>
									</p>
								)}
								<a
									href={`mailto:${
										acf.applyEmail
											? acf.applyEmail
											: "rekrutacja@palettenwerk.pl"
									}`}
									className="btn"
									data-hover={t("Aplikuj", language)}
								>
									{t("Aplikuj", language)}
									<span className="btn-arrows"></span>
								</a>
							</div>
						</div>
						<div className="col-lg-8">
							<div
								className="recruitment-single--content"
								dangerouslySetInnerHTML={{ __html: content }}
							/>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default Career;
